import { removeRecursiveByService } from "../common/array/delete_params";
import { Auth, redirectAuthorizeError, tauiUrl } from "../data/auth";
import { Connection } from "../data/connection";
import { broadcastConnectionStatus } from "../data/connection-status";
import { forwardHaptic } from "../data/haptics";
import { DEFAULT_PANEL } from "../data/panel";
import { NumberFormat, TimeFormat } from "../data/translation";
import { subscribeComponents } from "../data/ws-components";
import { subscribeConfig } from "../data/ws-config";
import { subscribeScopes } from "../data/ws-scopes";
import { subscribeTenants } from "../data/ws-tenants";
import { TauiScope } from "../resources/taui-scope";
import { tauiStructure } from "../resources/taui-structure";
import { translationMetadata } from "../resources/translations-metadata";
import { Constructor, PanelInfo, Panels, TauiServices } from "../types";
import type { TauiComponents } from "../types/config";
import { ERR_INVALID_AUTH } from "../websocket/error";
import { fetchWithAuth } from "../util/fetch-with-auth";
import tauiCallApi from "../util/taui-call-api";
import { getState } from "../util/taui-pref-storage";
import { getLocalSelectedTenant } from "../util/taui-tenant";
import { getLocalLanguage } from "../util/taui-translation";
import { TauiBaseEl } from "./taui-base-mixin";
import { saveTokens } from "../common/auth/token_storage";

export const connectionMixin = <T extends Constructor<TauiBaseEl>>(
  superClass: T
) =>
  class extends superClass {
    private __callCount = 0;

    protected initializeTaui(auth: Auth, conn: Connection) {
      const language = getLocalLanguage();

      this.taui = {
        auth,
        connection: conn,
        connected: true,
        config: null as any,
        structure: tauiStructure,
        themes: null as any,
        panels: null as any,
        components: null as any,
        panelUrl: (this as any)._panelUrl,
        services: null as any,
        language: getLocalLanguage(),
        selectedLanguage: null,
        defaultDashboard: -1,
        previousIdentity: null as any,
        selectedPanel: DEFAULT_PANEL,
        tabs: [],
        dynamicColumn: [],
        idsUpload: [],
        locale: {
          language,
          number_format: NumberFormat.language,
          time_format: TimeFormat.language,
          time_zone: "browser",
        },
        resources: null as any,
        localize: () => "",
        translationMetadata,
        dockedSidebar: "docked",
        vibrate: true,
        suspendWhenHidden: true,
        enableShortcuts: true,
        user: null as any,
        tenantMaster: auth.data.clientId === "master",
        allTenants: null as any,
        selectedTenant: getLocalSelectedTenant(),
        currentScopes: null as any,
        loading: false,
        hasAnyScopes: (scopes?: TauiScope[]): boolean => {
          if (scopes && scopes?.length > 0) {
            if ((this as any).taui && (this as any).taui!.currentScopes) {
              const find = scopes.filter((s) =>
                (this as any).taui!.currentScopes.includes(s)
              );
              return find.length > 0;
            }
          }

          return true;
        },
        hasService: (service: string) => {
          if ((this as any).taui && (this as any).taui!.components) {
            const component = (this as any).taui!.components.find(
              (el) => el.displayName === service
            );

            if (component) {
              return true;
            }
          }

          return false;
        },
        upload: {
          current: undefined,
          next: [],
        },
        tauiUrl: (path = "") => new URL(path, auth.data.tauiUrl).toString(),
        callApi: async (method, service, path, parameters, headers) => {
          if (service !== TauiServices.grpc) {
            if (!this.taui!.hasService(service)) {
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw {
                error: `ui.api.services.missing_service,service,${service}`,
                status_code: 0,
                body: null,
              };
            }
          }

          this._updateTaui({ loading: true });
          this.__callCount += 1;

          try {
            const resp = await tauiCallApi(
              auth,
              method,
              service,
              path,
              parameters,
              headers
            );

            this.__callCount -= 1;
            setTimeout(() => {
              if (this.__callCount === 0) {
                this._updateTaui({ loading: false });
              }
            }, 250);

            return await (resp as Promise<any>);
          } catch (err: any) {
            this.__callCount -= 1;
            setTimeout(() => {
              if (this.__callCount === 0) this._updateTaui({ loading: false });
            }, 250);

            forwardHaptic("failure");

            let errorMessage = "";

            if (err.status_code === 0 || err.status_code === 502) {
              errorMessage = "ui.api.http_error.service_not_available";
            } else if (err.status_code === 403) {
              errorMessage = "ui.api.http_error.access_denied";
            } else if (err.status_code === 500) {
              errorMessage = "ui.api.http_error.internal_server_error";
            }

            if (errorMessage)
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw {
                error: errorMessage,
                status_code: err.status_code,
                body: err.body,
              };

            if (__DEV__) {
              // eslint-disable-next-line no-console
              console.error("Error calling api", method, service, path, err);
            }

            throw err;
          }
        },
        fetchWithAuth: (
          path: string,
          init: Parameters<typeof fetchWithAuth>[2]
        ) => fetchWithAuth(auth, `${auth.data.tauiUrl}/api${path}`, init),
        ...getState(),
        ...this._pendingTaui,
      };

      this.tauiConnected();
    }

    protected tauiConnected() {
      super.tauiConnected();

      const conn = this.taui!.connection;

      broadcastConnectionStatus("connected");

      conn.addEventListener("ready", () => this.tauiReconnected());
      conn.addEventListener("disconnected", () => this.tauiDisconnected());
      // If we reconnect after losing connection and auth is no longer valid.
      conn.addEventListener("reconnect-error", (_conn, err) => {
        if (err === ERR_INVALID_AUTH) {
          broadcastConnectionStatus("auth-invalid");

          location.reload();
        }
      });

      subscribeConfig(conn, (config) => {
        this._updateTaui({ config });
        conn.debug = config?.websocket?.debug;
      });
      subscribeComponents(conn, (components) => {
        this._updateTaui({ components });

        const services = this._getServiceFromStructure(components);
        this._updateTaui({ services: services });

        const panels = this._getPanelFromStructure(services);
        this._updateTaui({ panels: panels });
      });
      subscribeTenants(conn, (allTenants) => {
        this._updateTaui({ allTenants });
      });
      subscribeScopes(conn, (scopes) => {
        const endUser =
          !scopes ||
          scopes.length === 0 ||
          scopes?.some(
            (s) =>
              [
                TauiScope.ACCOUNT_MANAGE,
                TauiScope.ACCOUNT_VIEW,
                TauiScope.CONTENT_BROWSE,
                TauiScope.CONTENT_CONSUME,
              ].indexOf(<TauiScope>s) >= 0
          );

        if (endUser) {
          saveTokens(null);
          redirectAuthorizeError(tauiUrl, "You have no scope or authority");
        }

        this._updateTaui({
          currentScopes: scopes?.sort((el1, el2) => (el1 < el2 ? -1 : 1)) || [],
        });
      });
    }

    protected tauiReconnected() {
      super.tauiReconnected();

      this._updateTaui({ connected: true });
      broadcastConnectionStatus("connected");
    }

    protected tauiDisconnected() {
      super.tauiDisconnected();

      this._updateTaui({ connected: false });
      broadcastConnectionStatus("disconnected");
    }

    private _getServiceFromStructure(components: TauiComponents): PanelInfo[] {
      let servicesAccessible: PanelInfo[] = [];

      const baseServices = [
        {
          displayName: "dashboard",
        },
        {
          displayName: "settings",
        },
      ];

      const services = JSON.parse(JSON.stringify(this.taui!.structure));

      const availableServices = [
        ...(components || []),
        ...(baseServices || []),
      ];

      const serviceItemDisabled = Object.values(TauiServices).filter(
        (service) => {
          const found = availableServices.find((availableService) =>
            availableService.displayName.includes(service)
          );
          return !found ? service : false;
        }
      );

      servicesAccessible = removeRecursiveByService(
        services,
        serviceItemDisabled
      );

      return Object.values(servicesAccessible);
    }

    private _getPanelFromStructure(services: PanelInfo[]) {
      let panels: Panels = {};

      Object.values(services).forEach((panel) => {
        panels = {
          ...panels,
          [panel.component_name]: {
            ...panel,
          },
        };
      });

      return panels;
    }
  };
