export enum TauiScope {
  // endUser
  ACCOUNT_VIEW = "account:view",
  ACCOUNT_MANAGE = "account:manage",
  CONTENT_BROWSE = "content:browse",
  CONTENT_CONSUME = "content:consume",

  // backendUser > dashboard
  DASHBOARD_VIEW = "dashboard:view",
  DASHBOARD_EDIT = "dashboard:edit",
  // backendUser > source
  SOURCE_CONTENT_VIEW = "source:content:view",
  SOURCE_CONTENT_EDIT = "source:content:edit",
  SOURCE_TENANT_VIEW = "source:tenant:view",
  SOURCE_TENANT_EDIT = "source:tenant:edit",
  SOURCE_TENANT_MANAGE = "source:tenant:manage",
  // backendUser > enrich
  ENRICH_VIEW = "enrich:view",
  ENRICH_EDIT = "enrich:edit",
  ENRICH_MANAGE = "enrich:manage",
  // backendUser > experience
  EXPERIENCE_CONTENT_VIEW = "experience:content:view",
  EXPERIENCE_CONTENT_EDIT = "experience:content:edit",
  EXPERIENCE_USER_VIEW = "experience:user:view",
  EXPERIENCE_USER_EDIT = "experience:user:edit",
  EXPERIENCE_USER_MANAGE = "experience:user:manage",
  EXPERIENCE_MONETIZATION_VIEW = "experience:monetization:view",
  EXPERIENCE_MONETIZATION_EDIT = "experience:monetization:edit",
  EXPERIENCE_MONETIZATION_MANAGE = "experience:monetization:manage",
  // backendUser > publish
  PUBLISH_CONTENT_VIEW = "publish:content:view",
  PUBLISH_CONTENT_EDIT = "publish:content:edit",
  PUBLISH_CONTENT_MANAGE = "publish:content:manage",
  PUBLISH_MANAGEMENT_VIEW = "publish:management:view",
  PUBLISH_MANAGEMENT_EDIT = "publish:management:edit",
  PUBLISH_MANAGEMENT_MANAGE = "publish:management:manage",
}
