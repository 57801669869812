import { saveTokens } from "../common/auth/token_storage";
import { Auth } from "../data/auth";

export const fetchWithAuth = async (
  auth: Auth,
  input: RequestInfo,
  init: RequestInit = {}
) => {
  if (auth.expired) {
    await auth.refreshAccessToken().catch((_err) => {
      saveTokens(null);
      location.reload();
    });
  }

  init.credentials = "same-origin";
  if (!init.headers) {
    init.headers = {};
  }
  if (!init.headers) {
    init.headers = {};
  }
  // @ts-ignore
  init.headers.authorization = `Bearer ${auth.accessToken}`;
  return fetch(input, init);
};
