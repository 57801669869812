import { TucanoAdminUI, TauiServices } from "../../types";
import {
  MediaDTO,
  MediaDTOList,
  MediaParams,
} from "../../interfaces/openapi/studio-api";

export const studioListMedias = async (taui: TucanoAdminUI, assetId: string) =>
  taui.callApi<MediaDTOList>(
    "GET",
    TauiServices.studio,
    `/asset/${assetId}/media`
  );

export const studioGetMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  mediaId: string
) =>
  taui.callApi<MediaDTO>(
    "GET",
    TauiServices.studio,
    `/asset/${assetId}/media/${mediaId}`
  );

export const studioCreateMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  params: MediaParams
) =>
  taui.callApi<MediaDTO>(
    "POST",
    TauiServices.studio,
    `/asset/${assetId}/media`,
    params
  );

export const studioUpdateMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  mediaId: string,
  params: MediaParams
) =>
  taui.callApi<MediaDTO>(
    "PUT",
    TauiServices.studio,
    `/asset/${assetId}/media/${mediaId}`,
    params
  );

export const studioDeleteMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  mediaId: string
) =>
  taui.callApi<void>(
    "DELETE",
    TauiServices.studio,
    `/asset/${assetId}/media/${mediaId}`
  );
