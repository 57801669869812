// @ts-ignore
// eslint-disable-next-line import/extensions
import { Resumable } from "resumablejs/resumable.js";
import { LocalizeFunc } from "./common/translations/localize";
import { Auth } from "./data/auth";
import { Connection } from "./data/connection";
import { TauiComponents, TauiConfig } from "./types/config";
import { IdentityDTO } from "./interfaces/openapi/identity-api";
import { FrontendLocaleData } from "./data/translation";
import { TauiScope } from "./resources/taui-scope";
import { Tenant } from "./interfaces/openapi/adminui-api";

declare global {
  /* eslint-disable no-var, no-redeclare, @typescript-eslint/naming-convention */
  var __DEV__: boolean;
  var __DEMO__: boolean;
  var __BUILD__: "latest" | "es5";
  var __VERSION__: string;
  var __STATIC_PATH__: string;
  /* eslint-enable no-var, no-redeclare, @typescript-eslint/naming-convention */

  interface Window {
    ShadyCSS: {
      nativeCss: boolean;
      nativeShadow: boolean;
      prepareTemplate(templateElement, elementName, elementExtension);
      styleElement(element);
      styleSubtree(element, overrideProperties);
      styleDocument(overrideProperties);
      getComputedStyleValue(element, propertyName);
    };
  }
  // for fire event
  interface TAUIDomEvents {
    "value-changed": {
      dirty?: boolean;
      value: unknown;
    };
    change: undefined;
    "data-changed": {
      path?: string;
      field?: string;
      separator?: string;
      data?: any;
      dirty?: boolean;
      toDelete?: boolean;
    };
    "error-object-changed": {
      name: string;
      value: unknown;
    };
  }

  // For loading workers in webpack
  interface ImportMeta {
    url: string;
  }
}

export type Constructor<T = any> = new (...args: any[]) => T;

export interface ClassElement {
  kind: "field" | "method";
  key: PropertyKey;
  placement: "static" | "prototype" | "own";
  initializer?: (...args) => unknown;
  extras?: ClassElement[];
  finisher?: <T>(cls: Constructor<T>) => undefined | Constructor<T>;
  descriptor?: PropertyDescriptor;
}

export interface Translation {
  nativeName: string;
  isRTL: boolean;
  hash: string;
}

export interface TranslationMetadata {
  fragments: string[];
  translations: {
    [lang: string]: Translation;
  };
}

export interface IconMetaFile {
  version: string;
  parts: IconMeta[];
}

export interface IconMeta {
  start: string;
  file: string;
}

export interface Resources {
  [language: string]: Record<string, string>;
}

export interface BackendLanguages {
  iso: string;
  language: string;
}

export interface Themes {
  default_theme: string;
  default_dark_theme: string | null;
  themes: any;
  // Currently effective dark mode. Will never be undefined. If user selected "auto"
  // in theme picker, this property will still contain either true or false based on
  // what has been determined via system preferences and support from the selected theme.
  darkMode: boolean;
  // Currently globally active theme name
  theme: string;
}

// Currently selected theme and its settings. These are the values stored in local storage.
// Note: These values are not meant to be used at runtime to check whether dark mode is active
// or which theme name to use, as this interface represents the config data for the theme picker.
// The actually active dark mode and theme name can be read from taui.themes.
export interface ThemeSettings {
  theme: string;
  // Radio box selection for theme picker. Do not use in Dashboard rendering as
  // it can be undefined == auto.
  // Property taui.themes.darkMode carries effective current mode.
  dark?: boolean;
  primaryColor?: string;
  accentColor?: string;
}

export enum TauiServices {
  // Backend services
  "analytics" = "analytics",
  "billing" = "billing",
  "catchup" = "catchup",
  "cms" = "cms",
  "crm" = "crm",
  "dashboard" = "dashboard",
  "enrichment" = "enrichment",
  "entitlements" = "entitlements",
  "export" = "export",
  "identity" = "identity",
  "image" = "image",
  "ipinfo" = "ipinfo",
  "npvr" = "npvr",
  "oidc" = "oidc",
  "privacy" = "privacy",
  "publication" = "publication",
  "realtime" = "realtime",
  "recommendation" = "recommendation",
  "reporting" = "reporting",
  "search" = "search",
  "studio" = "studio",
  "suboffer" = "suboffer",

  // AdminUI services
  "grpc" = "grpc",
}

export interface TabInfo
  extends Omit<PanelInfo, "children" | "component_name"> {
  base_url_path: string;
  component_name?: string;
  name?: string;
  edited?: boolean;
  error?: boolean;
}

export interface PanelInfo {
  component_name: string;
  icon?: string;
  children?: PanelInfo[];
  services?: string[];
  config?: string;
  url_path: string;
  search?: string;
  scopes?: TauiScope[];
}

export interface Panels {
  [name: string]: PanelInfo;
}

export interface UploadAsset {
  assetId: string;
  mediaId: string;
  r: Resumable;
  md5sum?: string;
}

export interface DynamicColumn {
  id: string;
  dynamicColumn: { index: number; checked: boolean }[];
}

export interface TucanoAdminUI {
  auth: Auth;
  connection: Connection;
  connected: boolean;
  config: TauiConfig;
  themes: Themes;
  panels: Panels;
  components?: TauiComponents;
  selectedTheme?: ThemeSettings | null;
  structure: any;
  panelUrl: string;
  services: PanelInfo[];
  language: string;
  // local stored language, keep that name for backward compability
  selectedLanguage: string | null;
  defaultDashboard: number;
  previousIdentity: number | null;
  selectedPanel: string;
  tabs: TabInfo[];
  dynamicColumn: DynamicColumn[];
  idsUpload: string[];
  locale: FrontendLocaleData;
  resources: Resources;
  localize: LocalizeFunc;
  translationMetadata: TranslationMetadata;
  suspendWhenHidden: boolean;
  enableShortcuts: boolean;
  vibrate: boolean;
  dockedSidebar: "docked" | "always_hidden" | "auto";
  user: IdentityDTO;
  tenantMaster: boolean;
  allTenants: Tenant[];
  selectedTenant: string;
  tenants: string[];
  currentScopes?: any;
  hasAnyScopes(scopes?: TauiScope[]): boolean;
  hasService?: any;
  upload: {
    current: UploadAsset | undefined;
    next: string[];
  };
  loading: boolean;
  tauiUrl(path?, fake?: boolean): string;
  callApi<T>(
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
    service: TauiServices,
    path: string,
    parameters?: Record<string, any>,
    headers?: Record<string, string>
  ): Promise<T>;
  fetchWithAuth(path: string, init?: Record<string, any>): Promise<Response>;
}

export interface Route {
  prefix: string;
  path: string;
  search?: string;
}

export interface BreadcrumbPath {
  key: string;
  label?: string;
  clickable: boolean;
}
