import { css, CSSResultGroup, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators";
import { fireEvent } from "../common/dom/fire_event";
import { TucanoAdminUI } from "../types";
import "./taui-icon-button";

@customElement("taui-menu-button")
class TauiMenuButton extends LitElement {
  @property() public narrow!: boolean;

  @property({ attribute: false }) public taui!: TucanoAdminUI;

  private _alwaysVisible = false;

  public disconnectedCallback() {
    super.disconnectedCallback();
  }

  protected render(): TemplateResult {
    return html`
      <taui-icon-button
        .label=${this.taui.localize("ui.sidebar.sidebar_toggle")}
        .icon=${"fad:bars"}
        @click=${this._toggleMenu}
      ></taui-icon-button>
    `;
  }

  protected updated(changedProps) {
    super.updated(changedProps);

    if (!changedProps.has("narrow") && !changedProps.has("taui")) {
      return;
    }

    const oldTaui = changedProps.get("taui") as TucanoAdminUI | undefined;
    const oldNarrow =
      changedProps.get("narrow") ||
      (oldTaui && oldTaui.dockedSidebar === "always_hidden");
    const newNarrow =
      this.narrow || this.taui.dockedSidebar === "always_hidden";

    if (oldNarrow === newNarrow) {
      return;
    }

    this.style.display = newNarrow || this._alwaysVisible ? "initial" : "none";
  }

  private _toggleMenu(): void {
    fireEvent(this, "taui-sidebar-toggle-menu");
  }

  static get styles(): CSSResultGroup {
    return css`
      :host {
        position: relative;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-menu-button": TauiMenuButton;
  }
}
