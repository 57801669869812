import { html, LitElement, TemplateResult } from "lit";
import { customElement, property, state } from "lit/decorators";
import { TucanoAdminUI } from "../types";
import "./taui-icon-button";

@customElement("taui-icon-button-arrow-prev")
export class TauiIconButtonArrowPrev extends LitElement {
  @property({ attribute: false }) public taui?: TucanoAdminUI;

  @property({ type: Boolean }) public disabled = false;

  @property() public label?: string;

  @state() private _icon = "fad:arrow-left";

  public connectedCallback() {
    super.connectedCallback();

    // wait to check for direction since otherwise direction is wrong even though top level is RTL
    setTimeout(() => {
      this._icon =
        window.getComputedStyle(this).direction === "ltr"
          ? "fad:arrow-left"
          : "fad:arrow-right";
    }, 100);
  }

  protected render(): TemplateResult {
    return html`
      <taui-icon-button
        .disabled=${this.disabled}
        .label=${this.label || this.taui?.localize("ui.common.back") || "Back"}
        .path=${this._icon}
      ></taui-icon-button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-icon-button-arrow-prev": TauiIconButtonArrowPrev;
  }
}
