import { Settings } from "luxon";
import { Constructor } from "../types";
import { clearState, storeState } from "../util/taui-pref-storage";
import { TauiBaseEl } from "./taui-base-mixin";
import { fireEvent } from "../common/dom/fire_event";
import { polyfillsLoaded } from "../common/translations/localize";
import { subscribeUser, userCollection } from "../data/ws-user";
import { tenantsCollection } from "../data/ws-tenants";

declare global {
  // for fire event
  interface TAUIDomEvents {
    "identity-changed": number;
    "taui-refresh-current-user": undefined;
    "taui-refresh-all-tenants": undefined;
  }
}

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("taui-logout", () => this._handleLogout());
      this.addEventListener("identity-changed", (ev) =>
        this._identityChanged(ev)
      );
      this.addEventListener("taui-refresh-current-user", async () => {
        userCollection(this.taui!.connection).refresh();
      });
      this.addEventListener("taui-refresh-all-tenants", async () => {
        tenantsCollection(this.taui!.connection).refresh();
      });
    }

    protected tauiConnected() {
      super.tauiConnected();

      subscribeUser(this.taui!.connection, (user) => {
        this._updateTaui({ user });

        // @ts-ignore
        const locale = user?.metadata?.config?.locale;

        if (
          locale?.language &&
          this.taui!.locale.language !== locale.language
        ) {
          fireEvent(this as any, "taui-language-select", locale.language);
        }

        if (
          locale?.number_format &&
          this.taui!.locale.number_format !== locale.number_format
        ) {
          fireEvent(
            this as any,
            "taui-number-format-select",
            locale.number_format
          );
        }

        if (
          locale?.time_format &&
          this.taui!.locale.time_format !== locale.time_format
        ) {
          fireEvent(this as any, "taui-time-format-select", locale.time_format);
        }

        let time_zone;
        if (
          locale?.time_zone &&
          this.taui!.locale.time_zone !== locale.time_zone
        ) {
          time_zone = locale.time_zone;
          fireEvent(this as any, "taui-time-zone-select", time_zone);
        } else {
          time_zone = "browser";
          if (this.taui!.locale.time_zone !== time_zone)
            fireEvent(this as any, "taui-time-zone-select", time_zone);
        }

        const currentTimezone =
          time_zone === "browser"
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : time_zone;
        // Configure the default time zone
        Settings.defaultZone = currentTimezone;

        if (__BUILD__ === "latest" && polyfillsLoaded) {
          polyfillsLoaded.then(() => {
            if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
              // @ts-ignore
              Intl.DateTimeFormat.__setDefaultTimeZone(currentTimezone);
            }
          });
        } else if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
          // @ts-ignore
          Intl.DateTimeFormat.__setDefaultTimeZone(currentTimezone);
        }
      });
    }

    private async _handleLogout() {
      try {
        await this.taui!.auth.revoke();
        // this.taui!.connection.close();
        clearState(false);
        document.location.href = "/";
      } catch (err: any) {
        // eslint-disable-next-line
        console.error(err);
        alert("Log out failed");
      }
    }

    private _identityChanged(ev) {
      this._updateTaui({
        previousIdentity: ev.detail,
      });
      storeState(this.taui!);
    }
  };
