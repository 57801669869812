import * as SparkMD5 from "spark-md5";
// @ts-ignore
// eslint-disable-next-line import/extensions
import * as Resumable from "resumablejs/resumable.js";
import { Constructor, UploadAsset } from "../types";
import { TauiBaseEl } from "./taui-base-mixin";
import { fireEvent } from "../common/dom/fire_event";
import { studioDeleteMedia } from "../data/studio/media";
import { studioAssembleChunks, studioResetUpload } from "../data/studio/upload";
import { getMainError } from "../common/util/manager_error";
import {
  showAlertDialog,
  showConfirmationDialog,
} from "../dialogs/generic/show-dialog-box";
import { saveTokens } from "../common/auth/token_storage";
import { MediaDTO } from "../interfaces/openapi/studio-api";

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-upload-set-node": HTMLElement;
    "taui-upload-remove-node": HTMLElement;
    "taui-upload-set-next": {
      value: string[];
    };
    "taui-upload-add": {
      assetId: string;
      mediaId: string;
      file: File;
    };
    "taui-upload-media-added": undefined;
    "taui-upload-pause": MediaDTO;
    "taui-upload-cancel": MediaDTO;
    "taui-upload-resume": MediaDTO;
    "ids-upload-changed": string[];
  }
}

export enum assetUploadStatus {
  ONGOING = "ONGOING",
  ASSEMBLING = "ASSEMBLING",
  FAILED = "FAILED",
  PAUSED = "PAUSED",
  QUEUED = "QUEUED",
  NEW = "NEW",
  PENDING_DELETED = "PENDING_DELETED",
  FINISHED = "FINISHED",
  ACQUISITION_ONGOING = "ACQUISITION_ONGOING",
}

export const assetUploadStatusPriority = [
  assetUploadStatus.ACQUISITION_ONGOING,
  assetUploadStatus.ONGOING,
  assetUploadStatus.ASSEMBLING,
  assetUploadStatus.FAILED,
  assetUploadStatus.PAUSED,
  assetUploadStatus.QUEUED,
  assetUploadStatus.NEW,
  assetUploadStatus.PENDING_DELETED,
  assetUploadStatus.FINISHED,
];

/*
 * superClass needs to contain `this.taui` and `this._updateTaui`.
 */

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    private _nodes: HTMLElement[] = [];

    private _uploadSelected: MediaDTO | undefined;

    private _resumableFiles: UploadAsset[] = [];

    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("taui-upload-set-node", (e) => {
        this._nodes.push((e as CustomEvent).detail);
      });
      this.addEventListener("taui-upload-remove-node", (e) => {
        this._nodes = (e as CustomEvent).detail;
      });
      this.addEventListener("taui-upload-set-next", (e) => {
        this._setNextUpload((e as CustomEvent).detail.value);
      });
      this.addEventListener("taui-upload-add", (e) => {
        const value = (e as CustomEvent).detail;
        this._addUpload(value.assetId, value.mediaId, value.file);
      });
      this.addEventListener("taui-upload-media-added", (_e) => {
        this._mediaAdded();
      });
      this.addEventListener("taui-upload-pause", (e) =>
        this._pauseUpload((e as CustomEvent).detail)
      );
      this.addEventListener("taui-upload-cancel", (e) =>
        this._cancelUpload((e as CustomEvent).detail)
      );
      this.addEventListener("taui-upload-resume", (e) =>
        this._resumeUpload((e as CustomEvent).detail)
      );
    }

    private _setCurrentUpload(resumableFile: UploadAsset | undefined) {
      this._updateTaui({
        upload: { ...this.taui!.upload, current: resumableFile },
      });
    }

    private _setNextUpload(next: string[]) {
      this._updateTaui({
        upload: { ...this.taui!.upload, next },
      });
    }

    private async _initUpload(assetId, mediaId, fileToAdd?: File) {
      const r = new Resumable({
        // @ts-ignore
        target: (params) => {
          const mediaIdParams = params.find((str) => str.includes("mediaId="));
          const mediaIdFromParams = mediaIdParams
            ? mediaIdParams.replace("mediaId=", "")
            : "";
          return this.taui!.tauiUrl(
            `/api/studio/media/${mediaIdFromParams}/upload/chunk?${params.join(
              "&"
            )}`
          );
        },
        chunkSize: 1 * this.taui!.config!.studio!.upload!.chunkSize!,
        maxChunkRetries: this.taui!.config!.studio!.upload!.maxChunkRetries!,
        simultaneousUploads:
          this.taui!.config!.studio!.upload!.chunkSimultaneous!,
        permanentSuccess: [200, 201, 204],
        permanentErrors: [400, 404, 409, 415, 500, 501],
        testChunks: true,
        chunkRetryInterval: 5000,
        maxFiles: 1,
        // @ts-ignore
        generateUniqueIdentifier: (files) => files.mediaId,
        uploadMethod: "PUT",
        testMethod: "HEAD",
        method: "octet",
        headers: () => ({
          Authorization: "Bearer " + this.taui!.auth.accessToken,
        }),
        chunkNumberParameterName: "chunkNumber",
        totalChunksParameterName: "totalChunksCount",
        chunkSizeParameterName: "chunkSize",
        totalSizeParameterName: "fileSize",
        identifierParameterName: "mediaId",
        fileNameParameterName: "fileName",
        relativePathParameterName: "relativePath",
        currentChunkSizeParameterName: "currentChunkSize",
        typeParameterName: "type",
      });

      // Resumable.js isn't supported, fall back on a different method
      if (!r.support) {
        await showAlertDialog(this as any, {
          text: this.taui!.localize(
            "ui.components.upload.upload_file_api_unsupported"
          ),
        });
      } else {
        // Handle file add event
        r.on("fileAdded", (file) => {
          let isUploading = false;
          if (this.taui!.config!.studio!.upload!.simultaneous === 1) {
            this._resumableFiles.some((element) => {
              if (element.r.isUploading()) {
                isUploading = true;
                return true;
              }
              return false;
            });
          }

          const resumableFile: UploadAsset | undefined =
            this._resumableFiles.find(
              (item) => item.mediaId === file.uniqueIdentifier
            );

          if (resumableFile) {
            if (isUploading) {
              fireEvent(this as any, "taui-notification", {
                message:
                  "Only 1 upload is allowed, this upload is in queue and will start at the end of the current upload.",
                type: "warning",
              });

              this._nodes.forEach((node) => {
                // @ts-ignore
                node?.fileInQueue({
                  assetId: resumableFile.assetId,
                  mediaId: file.uniqueIdentifier,
                });

                if (
                  this.taui!.upload.next.indexOf(file.uniqueIdentifier) === -1
                ) {
                  fireEvent(this as any, "taui-upload-set-next", {
                    value: [
                      ...(this.taui!.upload.next || []),
                      file.uniqueIdentifier,
                    ],
                  });
                }
              });
            } else {
              r.upload();

              this._setCurrentUpload(resumableFile);

              this._calculate(file.file, (md5sum) => {
                resumableFile.md5sum = md5sum;
              });
            }
          }
        });
        r.on("pause", () => {
          this._setCurrentUpload(undefined);

          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.filePause({ assetId: assetId, mediaId: mediaId });
          });

          if (this._uploadSelected && this._uploadSelected.id) {
            const index = this.taui!.upload.next.indexOf(
              this._uploadSelected.id
            );
            if (index > -1) {
              fireEvent(this as any, "taui-upload-set-next", {
                value: [
                  ...this.taui!.upload.next.filter(
                    (nextUpload) => nextUpload !== this._uploadSelected!.id
                  ),
                ],
              });
            }
          }
        });
        r.on("fileSuccess", (file, _message) => {
          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.fileSuccess({
              assetId: assetId,
              mediaId: file.uniqueIdentifier,
            });
          });

          const indexNextUpload = this.taui!.upload.next.indexOf(
            file.uniqueIdentifier
          );
          if (indexNextUpload > -1) {
            fireEvent(this as any, "taui-upload-set-next", {
              value: [
                ...this.taui!.upload.next.filter(
                  (nextUpload) => nextUpload !== file.uniqueIdentifier
                ),
              ],
            });
          }

          this.checkNextUpload();

          const resumableFile: UploadAsset | undefined =
            this._resumableFiles.find(
              (item) => item.mediaId === file.uniqueIdentifier
            );

          if (resumableFile) {
            if (resumableFile.md5sum) {
              this._nodes.forEach((node) => {
                // @ts-ignore
                node?.fileSuccess({
                  assetId: resumableFile.assetId,
                  mediaId: file.uniqueIdentifier,
                });
              });
              this._assembleMedia(
                resumableFile.assetId,
                file.uniqueIdentifier,
                resumableFile.md5sum
              );
            } else {
              this._calculate(file.file, (md5sum) => {
                resumableFile.md5sum = md5sum;

                this._assembleMedia(
                  resumableFile.assetId,
                  file.uniqueIdentifier,
                  resumableFile.md5sum
                );
              });
            }
          }
        });
        r.on("fileRetry", async () => {
          await this.taui!.auth.refreshAccessToken().catch((_err) => {
            saveTokens(null);
            location.reload();
          });
        });
        r.on("fileError", (file, message) => {
          const data = JSON.parse(message);

          if (data && data.error) {
            if (data.error.details)
              fireEvent(this as any, "taui-notification", {
                message: getMainError(this.taui!.localize, data.error),
                type: "error",
              });

            if (data.error.message)
              fireEvent(this as any, "taui-notification", {
                message: getMainError(this.taui!.localize, {
                  error: data.error.message,
                }),
                type: "error",
              });
          }

          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.fileError({
              assetId: assetId,
              mediaId: file.uniqueIdentifier,
              message: message,
            });
          });

          const index = this.taui!.upload.next.indexOf(file.uniqueIdentifier);
          if (index > -1) {
            fireEvent(this as any, "taui-upload-set-next", {
              value: [
                ...this.taui!.upload.next.filter(
                  (nextUpload) => nextUpload !== file.uniqueIdentifier
                ),
              ],
            });
          }

          this.checkNextUpload();
        });
        r.on("fileProgress", (file) => {
          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.fileProgress({
              assetId: assetId,
              mediaId: file.uniqueIdentifier,
              // @ts-ignore
              progress: file.progress(),
            });
          });
        });
        r.on("cancel", () => {
          this._deleterMedia(assetId, mediaId);

          this.checkNextUpload();
        });
      }

      this._resumableFiles = [
        ...this._resumableFiles,
        {
          assetId: assetId,
          mediaId: mediaId,
          r: r,
        },
      ];

      if (fileToAdd) {
        // @ts-ignore
        r.addFile(fileToAdd);
      }
    }

    private _pauseUpload(media: MediaDTO) {
      this._uploadSelected = media;

      const resumableFile: UploadAsset | undefined = this._resumableFiles.find(
        (item) => item.mediaId === media.id
      );

      if (resumableFile) {
        if (
          resumableFile.r.files.find(
            (item) => item.uniqueIdentifier === media.id
          )
        )
          resumableFile.r.pause();
      }
    }

    private _cancelUpload(media: MediaDTO) {
      this._uploadSelected = media;

      let found = false;
      const resumableFile: UploadAsset | undefined = this._resumableFiles.find(
        (item) => item.mediaId === media.id
      );

      if (resumableFile) {
        if (
          resumableFile.r.files.find(
            (item) => item.uniqueIdentifier === media.id
          )
        ) {
          found = true;
          resumableFile.r.cancel();
        }
      }

      if (!found) {
        this._deleterMedia(media.assetId, media.id);
      }
    }

    private _resumeUpload(media: MediaDTO) {
      this._uploadSelected = media;

      let found = false;
      let resumableFile: any = null;

      if (this._resumableFiles) {
        resumableFile = this._resumableFiles.find(
          (item) => item.mediaId === media.id
        );

        if (resumableFile) {
          if (
            resumableFile.r.files.find(
              (item) => item.uniqueIdentifier === media.id
            )
          )
            found = true;
        }
      }

      let isUploading = false;
      if (this.taui!.config!.studio!.upload!.simultaneous === 1) {
        this._resumableFiles.some((element) => {
          if (element.r.isUploading()) {
            isUploading = true;
            return true;
          }
          return false;
        });
      }

      if (found && resumableFile) {
        if (!isUploading) {
          if (resumableFile.r.files[0].isComplete()) {
            if (resumableFile.md5sum) {
              this._assembleMedia(
                undefined,
                resumableFile.r.files[0].uniqueIdentifier,

                resumableFile.md5sum
              );
            } else {
              this._calculate(resumableFile.r.files[0].file, (md5sum) => {
                resumableFile.md5sum = md5sum;
                this._assembleMedia(
                  undefined,
                  resumableFile.r.files[0].uniqueIdentifier,
                  md5sum
                );
              });
            }
          } else {
            this._setCurrentUpload(resumableFile);

            resumableFile.r.upload();
            /* if (asset) {
              const media = this.mediasTable.find((item) => item.id === asset.id);
              if (media) {
                media.sourceParameters.uploadStatus = "ONGOING";

                media.button = {
                  disabled: {
                    resume: true,
                    pause: false,
                    cancel: false,
                  },
                  show: {
                    resume: false,
                    pause: true,
                    cancel: true,
                  },
                };

                this.notifyPath("mediasTable", this.mediasTable.slice());
                this.updateAssetStatus(asset.id);
              }
            } */
          }
        } else {
          fireEvent(this as any, "taui-notification", {
            message:
              "Only 1 upload is allowed, this upload is in queue and will start at the end of the current upload.",
            type: "warning",
          });

          /* this._updateFileInQueue({
            detail: { mediaId: resumableFile.r.files[0].uniqueIdentifier },
          }); */
        }
      } else {
        this._openBrowser();
      }
    }

    checkNextUpload() {
      if (this.taui!.upload.next.length > 0) {
        let found = false;

        const resumableFile: UploadAsset | undefined =
          this._resumableFiles.find(
            (item) => item.mediaId === this.taui!.upload.next[0]
          );

        if (resumableFile) {
          if (
            resumableFile.r.files.find(
              (item) => item.uniqueIdentifier === this.taui!.upload.next[0]
            )
          ) {
            found = true;

            this._setCurrentUpload(resumableFile);

            resumableFile.r.upload();
          }
        }

        if (!found) {
          this._setCurrentUpload(undefined);
        }
      } else {
        this._setCurrentUpload(undefined);
      }
    }

    private _calculate(file, callBack) {
      const fileReader = new FileReader();

      const blobSlice =
        // @ts-ignore
        File.prototype.mozSlice ||
        // @ts-ignore
        File.prototype.webkitSlice ||
        File.prototype.slice;
      const chunkSize = 2097152;
      // read in chunks of 2MB
      const chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      const spark = new SparkMD5();

      fileReader.onload = (e) => {
        spark.appendBinary(e.target?.result); // append binary string
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          callBack(spark.end());
        }
      };

      function loadNext() {
        const start = currentChunk * chunkSize;
        const end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsBinaryString(blobSlice.call(file, start, end));
      }

      loadNext();
    }

    private _openBrowser() {
      const inputFile = document.createElement("input");
      inputFile.type = "file";
      inputFile.name = "file";
      inputFile.id = "file";
      inputFile.style.display = "none";

      inputFile.onchange = async (ev) => {
        const files = (ev.target as HTMLInputElement).files;

        if (
          files &&
          files.length > 0 &&
          this._uploadSelected &&
          this._uploadSelected.sourceParameters &&
          "fileName" in this._uploadSelected.sourceParameters
        ) {
          if (
            files[0].name === this._uploadSelected.sourceParameters.fileName
          ) {
            this._addUpload(
              this._uploadSelected.assetId,
              this._uploadSelected.id,
              files[0]
            );
          } else {
            const confirmed = await showConfirmationDialog(this as any, {
              title: this.taui!.localize(
                "ui.components.upload.reset_upload_title"
              ),
              text: this.taui!.localize(
                "ui.components.upload.reset_upload_content",
                {
                  fileName: this._uploadSelected.sourceParameters.fileName,
                }
              ),
              confirmText: this.taui!.localize("ui.common.confirm"),
              dismissText: this.taui!.localize("ui.common.cancel"),
            });

            if (confirmed) {
              studioResetUpload(this.taui!, this._uploadSelected.id!).then(
                (_data) => {
                  this._addUpload(
                    this._uploadSelected!.assetId,
                    this._uploadSelected!.id,
                    files[0]
                  );

                  fireEvent(this as any, "taui-notification", {
                    message: this.taui!.localize(
                      "ui.components.upload.new_upload_started"
                    ),
                    type: "success",
                  });
                },
                (err) => {
                  if (err.error)
                    fireEvent(this as any, "taui-notification", {
                      message: getMainError(this.taui!.localize, err),
                      type: "error",
                    });
                }
              );
            }
          }
        }
      };
      this.appendChild(inputFile);
      inputFile.click();
    }

    private _addUpload(assetId, mediaId, file) {
      if (assetId && mediaId && file) {
        file.mediaId = mediaId;

        this._initUpload(assetId, mediaId, file).then(() => {});
      }
    }

    private _mediaAdded() {
      this._nodes.forEach((node) => {
        // @ts-ignore
        node?.mediaAdded();
      });
    }

    private _assembleMedia(assetId, mediaId, md5sum) {
      this._nodes.forEach((node) => {
        // @ts-ignore
        node?.fileAssemble({
          assetId: assetId,
          mediaId: mediaId,
          md5sum: md5sum,
        });
      });

      studioAssembleChunks(this.taui!, mediaId, { md5sum: md5sum }).then(
        (_data) => {
          const uploadIds = this.taui!.idsUpload;
          const indexIdsUpload = uploadIds.indexOf(mediaId);
          if (indexIdsUpload > -1) {
            uploadIds.splice(indexIdsUpload, 1);
            fireEvent(this as any, "ids-upload-changed", uploadIds);
          }

          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.fileAssembleResult({
              assetId: assetId,
              mediaId: mediaId,
              success: true,
            });
          });
        },
        (err) => {
          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.fileAssembleResult({
              assetId: assetId,
              mediaId: mediaId,
              success: false,
            });
          });

          if (err.error)
            fireEvent(this as any, "taui-notification", {
              message: getMainError(this.taui!.localize, err),
              type: "error",
            });
        }
      );
    }

    private _deleterMedia(assetId, mediaId) {
      studioDeleteMedia(this.taui!, assetId, mediaId).then(
        (_data) => {
          fireEvent(this as any, "taui-notification", {
            message: this.taui!.localize(
              "ui.components.upload.deleted_success"
            ),
            type: "success",
          });

          this._nodes.forEach((node) => {
            // @ts-ignore
            node?.mediaDeleted();
          });
        },
        (err) => {
          if (err.error)
            fireEvent(this as any, "taui-notification", {
              message: getMainError(this.taui!.localize, err),
              type: "error",
            });
        }
      );
    }
  };
