import { render, TemplateResult } from "lit";

export const removeLaunchScreen = () => {
  const initEl = document.getElementById("taui-launch-screen");
  if (initEl) {
    initEl.parentElement!.removeChild(initEl);
  }
};

export const renderLaunchScreenInfoBox = (content: TemplateResult) => {
  const infoBoxElement = document.getElementById("taui-launch-screen-info-box");
  if (infoBoxElement) {
    render(content, infoBoxElement);
  }
};

export const renderAdaptedLogo = (beeMode = false) => {
  const screenLogo = document.getElementById(
    "taui-launch-screen-logo"
  ) as HTMLDivElement;

  const logo = new Image(100, 100);
  logo.src = beeMode
    ? "/static/images/bee/Bee-icon-colour-bg-light.png"
    : "/static/images/tucano/tucano-icon-colour-bg-light.svg";

  if (screenLogo) {
    screenLogo.appendChild(logo);
    if (logo.src) screenLogo.style.opacity = "1";
  }
};
