import "@material/mwc-menu";
import type { Corner, Menu, MenuCorner } from "@material/mwc-menu";
import {
  css,
  CSSResultGroup,
  html,
  LitElement,
  PropertyValues,
  TemplateResult,
} from "lit";
import {
  customElement,
  property,
  query,
  queryAssignedElements,
} from "lit/decorators";
import { FOCUS_TARGET } from "../dialogs/make-dialog-manager";
import type { TauiButton } from "./taui-button";
import type { TauiIconButton } from "./taui-icon-button";

@customElement("taui-button-menu")
export class TauiButtonMenu extends LitElement {
  protected readonly [FOCUS_TARGET];

  @property() public corner: Corner = "TOP_START";

  @property() public menuCorner: MenuCorner = "START";

  @property({ type: Number }) public x: number | null = null;

  @property({ type: Number }) public y: number | null = null;

  @property({ type: Boolean }) public multi = false;

  @property({ type: Boolean }) public activatable = false;

  @property({ type: Boolean }) public disabled = false;

  @property({ type: Boolean }) public fixed = false;

  @property({ type: Boolean }) public opened = false;

  @query("mwc-menu", true) private _menu?: Menu;

  @queryAssignedElements({
    slot: "trigger",
    selector: "taui-button, taui-icon-button",
  })
  private _triggerButton!: Array<TauiIconButton | TauiButton>;

  public get items() {
    return this._menu?.items;
  }

  public get selected() {
    return this._menu?.selected;
  }

  public override focus() {
    if (this._menu?.open) {
      this._menu.focusItemAtIndex(0);
    } else {
      this._triggerButton[0]?.focus();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div @click=${this._handleClick}>
        <slot name="trigger"></slot>
      </div>
      <mwc-menu
        .corner=${this.corner}
        .open=${this.opened}
        .menuCorner=${this.menuCorner}
        .fixed=${this.fixed}
        .multi=${this.multi}
        .activatable=${this.activatable}
        .y=${this.y}
        .x=${this.x}
      >
        <slot></slot>
      </mwc-menu>
    `;
  }

  protected firstUpdated(changedProps): void {
    super.firstUpdated(changedProps);

    if (document.dir === "rtl") {
      this.updateComplete.then(() => {
        this.querySelectorAll("taui-list-item").forEach((item) => {
          const style = document.createElement("style");
          style.innerHTML =
            "span.material-icons:first-of-type { margin-left: var(--mdc-list-item-graphic-margin, 32px) !important; margin-right: 0px !important;}";
          item!.shadowRoot!.appendChild(style);
        });
      });
    }
  }

  protected updated(changedProps: PropertyValues) {
    super.updated(changedProps);

    if (changedProps.has("opened")) {
      if (this.opened) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    }
  }

  private _handleClick(): void {
    this.openMenu();
  }

  public openMenu() {
    if (this.disabled) {
      return;
    }

    this._menu!.anchor = this;
    this._menu!.show();
  }

  public closeMenu() {
    this._menu?.close();
  }

  static get styles(): CSSResultGroup {
    return css`
      :host {
        display: inline-block;
        position: relative;
      }
      ::slotted([disabled]) {
        color: var(--disabled-text-color);
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-button-menu": TauiButtonMenu;
  }
}
