import { css, CSSResultGroup, html, LitElement, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators";
import "../components/taui-circular-progress";
import "../components/taui-icon-button-arrow-prev";
import "../components/taui-menu-button";
import { tauiStyle } from "../resources/styles";
import { TucanoAdminUI } from "../types";

@customElement("taui-loading-screen")
class TauiLoadingScreen extends LitElement {
  @property({ attribute: false }) public taui?: TucanoAdminUI;

  @property({ type: Boolean, attribute: "no-toolbar" })
  public noToolbar = false;

  @property({ type: Boolean }) public rootnav = false;

  @property({ type: Boolean }) public narrow = false;

  protected render(): TemplateResult {
    return html`
      ${this.noToolbar
        ? ""
        : html`<div class="toolbar">
            ${this.rootnav || history.state?.root
              ? html`
                  <taui-menu-button
                    .taui=${this.taui}
                    .narrow=${this.narrow}
                  ></taui-menu-button>
                `
              : html`
                  <taui-icon-button-arrow-prev
                    .taui=${this.taui}
                    @click=${this._handleBack}
                  ></taui-icon-button-arrow-prev>
                `}
          </div>`}
      <div class="content">
        <taui-circular-progress active></taui-circular-progress>
      </div>
    `;
  }

  private _handleBack() {
    history.back();
  }

  static get styles(): CSSResultGroup {
    return [
      tauiStyle,
      css`
        :host {
          display: block;
          height: 100%;
          background-color: var(--primary-background-color);
        }
        .content {
          height: calc(100% - var(--header-height));
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `,
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-loading-screen": TauiLoadingScreen;
  }
}
